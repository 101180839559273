import HeaderSubPages from "../components/HeaderSubPages";
import '../assets/css/Progress.css'
import IGFeed from "../components/IGFeed";
import data from "../data/progress-sr.json"


function ProgressSeattleRidge() {
  return (
    <div className="progress">
      <div className="progress-header-sr">
        <HeaderSubPages />
      </div>
      <div className="progress-subheader">
        <h2>Seattle Ridge Project Progress</h2>
        <div className="main-text">
          <p>Opened on December 20, 2024, the new Seattle Ridge lift offers intermediate runs that make family mountain days more fun and tree-skiing that challenges experts, Bald Mountain&apos;s Seattle Ridge area is getting the “lift” this special terrain deserves.</p>
          <p>Check out the updates below to watch the project from start to finish!</p>
        </div>
      </div>


      <div className="progress-container">
        <div className="progress-line"></div>
        <div className="update-container">
          <h4>{data[0].date}</h4>
          <div className="progress-line-bullet"></div>
          <div className="update-text">
            <h3>{data[0].title}</h3>
            <p>{data[0].text}</p>
          </div>
          <div className="update-video">
            <iframe src={data[0].media} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
          </div>
        </div>
        <div className="update-container">
          <h4>{data[1].date}</h4>
          <div className="progress-line-bullet"></div>
          <div className="update-text">
            <h3>{data[1].title}</h3>
            <p>{data[1].text}</p>
          </div>
          <div className="update-video">
            <iframe src={data[1].media} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
          </div>
        </div>
        <div className="update-container">
          <h4>{data[2].date}</h4>
          <div className="progress-line-bullet"></div>
          <div className="update-text">
            <h3>{data[2].title}</h3>
            <p>{data[2].text}</p>
          </div>
          <div className="update-video">
            <iframe src={data[2].media} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
          </div>
        </div>
        <div className="update-container">
          <h4>{data[3].date}</h4>
          <div className="progress-line-bullet"></div>
          <div className="update-text">
            <h3>{data[3].title}</h3>
            <p>{data[3].text}</p>
          </div>
          <div className="update-video">
            <iframe src={data[3].media} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
          </div>
        </div>
        <div className="update-container">
          <h4>{data[4].date}</h4>
          <div className="progress-line-bullet"></div>
          <div className="update-text">
            <h3>{data[4].title}</h3>
            <p>{data[4].text}</p>
          </div>
          <div className="update-video">
            <iframe src={data[4].media} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
          </div>
        </div>
        <div className="update-container">
          <h4>{data[5].date}</h4>
          <div className="progress-line-bullet"></div>
          <div className="update-text">
            <h3>{data[5].title}</h3>
            <p>{data[5].text}</p>
          </div>
          <div className="update-video">
            <iframe src={data[5].media} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
          </div>
        </div>
        <div className="update-container">
          <h4>{data[6].date}</h4>
          <div className="progress-line-bullet"></div>
          <div className="update-text">
            <h3>{data[6].title}</h3>
            <p>{data[6].text}</p>
          </div>
          <div className="update-video">
            <iframe src={data[6].media} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
          </div>
        </div>
        <div className="update-container">
          <h4>{data[7].date}</h4>
          <div className="progress-line-bullet"></div>
          <div className="update-text">
            <h3>{data[7].title}</h3>
            <p>{data[7].text}</p>
          </div>
          <div className="update-video">
            <iframe src={data[7].media} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
          </div>
        </div>
        <div className="update-container">
          <h4>{data[8].date}</h4>
          <div className="progress-line-bullet"></div>
          <div className="update-text">
            <h3>{data[8].title}</h3>
            <p>{data[8].text}</p>
          </div>
          <div className="update-video">
            <iframe src={data[8].media} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
          </div>
        </div>
        <div className="update-container">
          <h4>{data[9].date}</h4>
          <div className="progress-line-bullet"></div>
          <div className="update-text">
            <h3>{data[9].title}</h3>
            <p>{data[9].text}</p>
          </div>
          <div className="update-video">
            <iframe src={data[9].media} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
          </div>
        </div>
      </div>
      <div className="ig-feed-progress">
        <div className="igfeed-progress-title">
          <h4>Keep Up to Date with @sunvalley</h4>
        </div>
        <IGFeed />
      </div>
    </div>
  );
}

export default ProgressSeattleRidge;